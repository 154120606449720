import { createAction } from 'typesafe-actions';
import { Currency } from '../types/enums/Currency';
import { AlertOption, EmailPreferences } from '../types/email-preferences/EmailPreferences';
import { Rating } from '../types/enums/Rating';
import { DailyStatsAlertsConfig, CompanyAlertsConfig, ImUserConfig, SecondaryStatsAlertsConfig } from '../types/user-config/UserConfig';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { StatisticsAlertOption } from '../types/amr-pipeline/enums/AlertOption';
import { AlertEnabledState } from '../types/enums/AlertEnabledState';
import { CloManager } from '../types/clo-managers/CloManager';
import { SavedFiltersPreferencesState } from '../types/state/ManageEmailPreferencesState';
import { Bank } from '../types/banks/Bank';

const getInitialDataRequest = createAction(
    'manageEmailPreferences/GET_INITIAL_DATA_REQUEST',
    resolve => (withIssuanceMonitorAccess: boolean = false) => resolve({ withIssuanceMonitorAccess })
);
const getInitialDataSuccess = createAction(
    'manageEmailPreferences/GET_INITIAL_DATA_SUCCESS',
    resolve => (
        preferences: EmailPreferences,
        userConfig?: ImUserConfig[],
        managers?: CloManager[],
        banks?: Bank[],
    ) => resolve({ preferences, userConfig, managers, banks })
);
const setRatingCheckedState = createAction(
    'manageEmailPreferences/SET_RATING_CHECKED_STATE',
    resolve => (rating: Rating, currency: Currency, checked: boolean) => resolve({ rating, currency, checked })
);
const setRatingInCurrencyCheckedState = createAction(
    'manageEmailPreferences/SET_RATING_IN_CURRENCY_CHECKED_STATE',
    resolve => (currency: Currency, checked: boolean) => resolve({ currency, checked })
);
const setAllRatingCheckedState = createAction(
    'manageEmailPreferences/SET_ALL_RATING_CHECKED_STATE',
    resolve => (checked: boolean) => resolve({ checked })
);
const setRatingSize = createAction(
    'manageEmailPreferences/SET_RATING_MIN_SIZE',
    resolve => (rating: Rating, size: number, type: 'minSize' | 'maxSize') => resolve({ rating, size, type })
);
const setCheckedDailyDashboardEmail = createAction(
    'manageEmailPreferences/SET_CHECKED_DAILY_DASHBOARD_EMAIL',
    resolve => (alertOption: AlertOption) => resolve({ alertOption })
);
const setPortfolioBwicAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_BWIC_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);

const setPortfolioIssuanceMonitorAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_ISSUANCE_MONITOR_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);

const setPortfolioRiAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_RI_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);

const setPortfolioNcAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_NC_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);

const setPortfolioRollerDeadlineAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_ROLLER_DEADLINE_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);

const setNewBiwicAlerts = createAction(
    'manageEmailPreferences/SET_NEW_BIWIC_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);
const setBiwicReminderAlerts = createAction(
    'manageEmailPreferences/SET_BIWIC_REMINDER_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);
const setPortfolioInventoryAlerts = createAction(
    'manageEmailPreferences/SET_PORTFOLIO_INVENTORY_ALERTS',
    resolve => (status: AlertEnabledState) => resolve({ status })
);
const setBwicByRatingPreferences = createAction(
    'manageEmailPreferences/SET_BWIC_BY_RATING_PREFERENCES',
    resolve => (bwicByRatingAlertState: AlertOption) => resolve({ bwicByRatingAlertState })
)
const setDealerInventoryAlertState = createAction(
    'manageEmailPreferences/SET_DEALER_INVENTORY_ALERTS',
    resolve => (dealerInventoryAlertState: AlertOption) => resolve({ dealerInventoryAlertState })
)
const setIssuanceMonitorSingleAlert = createAction(
    'manageEmailPreferences/SET_ISSUANCE_MONITOR_SINGLE_ALERT',
    resolve => (singleAlertState: AlertOption) => resolve({ singleAlertState })
);
const setIssuanceMonitorNewTransactionAlert = createAction(
    'manageEmailPreferences/SET_ISSUANCE_MONITOR_NEW_TRANSACTION_ALERT',
    resolve => (transactionAlertState: AlertOption) => resolve({ transactionAlertState })
);
const setIssuanceMonitorStatisticsAlert = createAction(
    'manageEmailPreferences/SET_ISSUANCE_MONITOR_STATISTICS_ALERT',
    resolve => (statisticAlertState: StatisticsAlertOption) => resolve({ statisticAlertState })
);
const setIssuanceMonitorFiltersAlert = createAction(
    'manageEmailPreferences/SET_ISSUANCE_MONITOR_FILTERS_ALERT',
    resolve => (filtersAlertState: UserConfigFilter[]) => resolve({ filtersAlertState })
);

const setArrangerPipelineAlert = createAction(
    'manageEmailPreferences/SET_ARRANGER_PIPELINE_ALERT',
    resolve => (apAlertOption: AlertOption) => resolve({ apAlertOption }),
);

const setCloManagersAnalyticsAlert = createAction(
    'manageEmailPreferences/SET_CLO_MANAGERS_ANALYTICS_ALERT',
    resolve => (cloManagerAnalyticsAlert: CompanyAlertsConfig) => resolve({ cloManagerAnalyticsAlert }),
);

const setCloManagersIssuanceMonitorAlert = createAction(
    'manageEmailPreferences/SET_CLO_MANAGERS_ISSUANCE_MONITOR_ALERT',
    resolve => (cloManagerImAlertsConfig: CompanyAlertsConfig) => resolve({ cloManagerImAlertsConfig }),
);

const setCloManagersArrangerPipelineAlert = createAction(
    'manageEmailPreferences/SET_CLO_MANAGERS_ARRANGER_PIPELINE_ALERT',
    resolve => (cloManagerImAlertsConfig: CompanyAlertsConfig) => resolve({ cloManagerImAlertsConfig }),
);

const setCloManagersBwicAlert = createAction(
    'manageEmailPreferences/SET_CLO_MANAGERS_BWIC_ALERT',
    resolve => (cloManagerBwicAlert: SecondaryStatsAlertsConfig) => resolve({ cloManagerBwicAlert }),
);

const setCloManagersDealerInventoryAlert = createAction(
    'manageEmailPreferences/SET_CLO_MANAGERS_DEALER_INVENTORY_ALERT',
    resolve => (cloManagerDealerInventoryAlert: SecondaryStatsAlertsConfig) => resolve({ cloManagerDealerInventoryAlert }),
);

const setBanksAnalyticsAlert = createAction(
    'manageEmailPreferences/SET_BANKS_ANALYTICS_ALERT',
    resolve => (bankAnalyticsAlertConfig: DailyStatsAlertsConfig) => resolve({ bankAnalyticsAlertConfig }),
);

const setBanksDealerInventoryAlert = createAction(
    'manageEmailPreferences/SET_BANKS_DEALER_INVENTORY_ALERT',
    resolve => (bankDiAlertConfig: SecondaryStatsAlertsConfig) => resolve({ bankDiAlertConfig }),
);

const setBanksIssuanceMonitorAlert = createAction(
    'manageEmailPreferences/SET_BANKS_ISSUANCE_MONITOR_ALERT',
    resolve => (bankImAlertsConfig: CompanyAlertsConfig) => resolve({ bankImAlertsConfig }),
);

const setBanksArrangerPipelineAlert = createAction(
    'manageEmailPreferences/SET_BANKS_ARRANGER_PIPELINE_ALERT',
    resolve => (bankApAlertsConfig: CompanyAlertsConfig) => resolve({ bankApAlertsConfig }),
);

const unsubscribeAllBWICsNotifications = createAction('manageEmailPreferences/UNSUBSCRIBE_ALL_BWIC_NOTIFICATIONS');
const unsubscribeAllIssuanceMonitor = createAction('manageEmailPreferences/UNSUBSCRIBE_ALL_ISSUANCE_MONITOR');
const unsubscribeAllCloManagers = createAction(
    'manageEmailPreferences/UNSUBSCRIBE_ALL_CLO_MANAGERS',
    resolve => (userCompanyReferenceName?: string) => resolve({ userCompanyReferenceName }),
);
const unsubscribeAllBanks = createAction(
    'manageEmailPreferences/UNSUBSCRIBE_ALL_BANKS',
    resolve => (userCompanyReferenceName?: string) => resolve({ userCompanyReferenceName }),
);

const unsubscribeAllPortfolio = createAction('manageEmailPreferences/UNSUBSCRIBE_ALL_PORTFOLIO');
const rollbackDashboardEmails = createAction('manageEmailPreferences/ROLLBACK_DASHBOARD_EMAILS');
const rollbackBWICPipelines = createAction('manageEmailPreferences/ROLLBACK_BWIC_PIPELINES');
const rollbackBWICNotifications = createAction('manageEmailPreferences/ROLLBACK_BWIC_NOTIFICATIONS');
const rollbackIssuanceMonitor = createAction('manageEmailPreferences/ROLLBACK_ISSUANCE_MONITOR');
const rollbackArrangerPipeline = createAction('manageEmailPreferences/ROLLBACK_ARRANGER_PIPELINE');
const rollbackCloManagers = createAction('manageEmailPreferences/ROLLBACK_CLO_MANAGERS');
const rollbackPortfolio = createAction('manageEmailPreferences/ROLLBACK_PORTFOLIO');
const rollbackInventory = createAction('manageEmailPreferences/ROLLBACK_INVENTORY');
const rollbackBanks = createAction('manageEmailPreferences/ROLLBACK_BANKS');
const saveBwicByRatingPreferencesRequest = createAction('manageEmailPreferences/BWIC_BY_RATING_SAVE_REQUEST');
const saveBwicByRatingPreferencesSuccess = createAction('manageEmailPreferences/BWIC_BY_RATING_SAVE_SUCCESS');
const saveBwicByRatingPreferencesFailure = createAction('manageEmailPreferences/BWIC_BY_RATING_SAVE_FAILURE');
const saveRequest = createAction('manageEmailPreferences/SAVE_REQUEST');
const saveArrangerPipelineRequest = createAction('manageEmailPreferences/SAVE_ARRANGER_PIPELINE_REQUEST');
const saveBanksRequest = createAction('manageEmailPreferences/SAVE_BANKS_REQUEST');
const saveSuccess = createAction('manageEmailPreferences/SAVE_SUCCESS');
const saveFailure = createAction('manageEmailPreferences/SAVE_FAILURE');
const saveEmailPreferencesRequest = createAction('manageEmailPreferences/SAVE_EMAIL_PREFERENCES_REQUEST');
const saveEmailPreferencesSuccess = createAction(
    'manageEmailPreferences/SAVE_EMAIL_PREFERENCES_SUCCESS',
    resolve => (emailPreferences: EmailPreferences) => resolve({ emailPreferences })
);
const saveEmailPreferencesFailure = createAction('manageEmailPreferences/SAVE_EMAIL_PREFERENCES_FAILURE');
const savedFilterAlertOptionChange = createAction('manageEmailPreferences/SAVED_FILTER_ALERT_OPTION_CHANGE',
    resolve => (updated: SavedFiltersPreferencesState) => resolve({ updated })
);
const savedFilterAlertOptionsReset = createAction('manageEmailPreferences/SAVED_FILTER_ALERT_OPTION_RESET');
const reset = createAction('manageEmailPreferences/RESET');
const initPortfolioFilterAlerts = createAction('manageEmailPreferences/INIT_PORTFOLIO_FILTER_ALERTS',
    resolve => (portfolioFilterAlerts: UserConfigFilter[]) => resolve({ portfolioFilterAlerts })
);
const resetPortfolioFilterAlerts = createAction('manageEmailPreferences/RESET_PORTFOLIO_FILTER_ALERTS');
const portfolioSavedFilterAlertChange = createAction("manageEmailPreferences/SAVED_FILTER_ALERT_CHHANGE",
    resolve => (filterReferenceName: string, bwicAlert: AlertOption | null, inventoryAlert: AlertOption | null) =>
        resolve({ filterReferenceName, bwicAlert, inventoryAlert }));
const portfolioSavedFilterGlobalAlertChange = createAction("manageEmailPreferences/SAVED_FILTER_GLOBAL_ALERT_CHANGE",
    resolve => (bwicAlert?: AlertOption, inventoryAlert?: AlertOption) =>
        resolve({ bwicAlert, inventoryAlert }));
const portfolioGlobalAlertsChange = createAction(
    'manageEmailPreferences/PORTFOLIO_GLOBAL_ALERTS_CHANGE',
    resolve =>
        (
            bwicGlobalAlertEnabled?: boolean,
            inventoryGlobalAlertEnabled?: boolean,
            issuanceMonitorAlertEnabled?: boolean,
            outOfRiAlertEnabled?: boolean,
            outOfNcAlertEnabled?: boolean,
            rollerDeadlineAlertEnabled?: boolean,
        ) =>
            resolve({
                bwicGlobalAlertEnabled,
                inventoryGlobalAlertEnabled,
                issuanceMonitorAlertEnabled,
                outOfRiAlertEnabled,
                outOfNcAlertEnabled,
                rollerDeadlineAlertEnabled,
            }),
);

export const manageEmailPreferencesActions = {
    getInitialDataRequest,
    getInitialDataSuccess,
    setRatingCheckedState,
    setRatingInCurrencyCheckedState,
    setAllRatingCheckedState,
    setRatingSize,
    setCheckedDailyDashboardEmail,
    setPortfolioBwicAlerts,
    setPortfolioInventoryAlerts,
    setBwicByRatingPreferences,
    setDealerInventoryAlertState,
    setIssuanceMonitorSingleAlert,
    setIssuanceMonitorNewTransactionAlert,
    setIssuanceMonitorStatisticsAlert,
    setIssuanceMonitorFiltersAlert,
    setArrangerPipelineAlert,
    setCloManagersAnalyticsAlert,
    setCloManagersIssuanceMonitorAlert,
    setCloManagersArrangerPipelineAlert,
    setCloManagersBwicAlert,
    setCloManagersDealerInventoryAlert,
    setBanksAnalyticsAlert,
    setBanksDealerInventoryAlert,
    setBanksIssuanceMonitorAlert,
    setBanksArrangerPipelineAlert,
    setNewBiwicAlerts,
    setBiwicReminderAlerts,
    setPortfolioIssuanceMonitorAlerts,
    setPortfolioRiAlerts,
    setPortfolioNcAlerts,
    setPortfolioRollerDeadlineAlerts,
    unsubscribeAllBWICsNotifications,
    unsubscribeAllIssuanceMonitor,
    unsubscribeAllCloManagers,
    unsubscribeAllPortfolio,
    unsubscribeAllBanks,
    rollbackDashboardEmails,
    rollbackBWICPipelines,
    rollbackBWICNotifications,
    rollbackIssuanceMonitor,
    rollbackArrangerPipeline,
    rollbackPortfolio,
    rollbackInventory,
    rollbackCloManagers,
    rollbackBanks,
    saveRequest,
    saveArrangerPipelineRequest,
    saveBanksRequest,
    saveSuccess,
    saveFailure,
    saveEmailPreferencesRequest,
    saveEmailPreferencesSuccess,
    saveEmailPreferencesFailure,
    saveBwicByRatingPreferencesRequest,
    saveBwicByRatingPreferencesSuccess,
    saveBwicByRatingPreferencesFailure,
    savedFilterAlertOptionChange,
    savedFilterAlertOptionsReset,
    reset,
    initPortfolioFilterAlerts,
    resetPortfolioFilterAlerts,
    portfolioSavedFilterAlertChange,
    portfolioSavedFilterGlobalAlertChange,
    portfolioGlobalAlertsChange
};
